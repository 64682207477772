import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { createDynamicURL } from '../../utils/dynamicParams';
// ----------------------------------------------------------------------
const initialState = {
    status: 'idle',
    meta: {
        currentPage: 0,
        nextPage: false,
        prevPage: false,
        totalPages: 0,
        totalRecords: 0,
    },
    rentableTypes: [],
    rentableType: null,
};
// ----------------------------------------------------------------------
export const getOneRentableType = createAsyncThunk('rentableType/getOneRentableType', async ({ id, isExtra }) => {
    let data;
    try {
        let url = `/rentableTypes/${id}`;
        if (isExtra !== null && isExtra !== undefined) {
            url = `${url}?isExtra=${isExtra}`;
        }
        const response = await axios.get(url);
        data = await response.data;
        if (response.status === 200) {
            return data.data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getRentableTypes = createAsyncThunk('rentableType/getRentableTypes', async ({ isExtra = '0', agencyIds = '', isPaging = false, page = '', rowsPerPage = '', orderBy = 'createdAt', order = 'desc', filterName = '', }) => {
    let data;
    const queryParams = {
        search: {
            isExtra,
            'class.agency.id': agencyIds,
            name: filterName,
            code: filterName,
        },
        page: Number(page) + 1,
        perPage: rowsPerPage,
        orderBy,
        sortedBy: order,
        isPaging,
        searchJoin: 'OR',
    };
    try {
        const url = createDynamicURL('/rentableTypes', queryParams);
        const response = await axios.get(url);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const createRentableType = createAsyncThunk('rentableType/createRentableType', async (Data) => {
    let data;
    try {
        const response = await axios.post(`/rentableTypes`, Data);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const editRentableType = createAsyncThunk('rentableType/editRentableType', async ({ id, Data }) => {
    let data;
    try {
        const response = await axios.put(`/rentableTypes/${id}`, Data);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const deleteRentableType = createAsyncThunk('rentableType/deleteRentableType', async ({ id, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.delete(`/rentableTypes/${id}`);
        data = await response.data;
        if (response.status === 200) {
            if (getParams)
                thunkAPI.dispatch(getRentableTypes({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const deleteManyRentableTypes = createAsyncThunk('rentableType/deleteManyRentableTypes', async ({ ids, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.delete(`/rentableTypes/many`, { data: { ids } });
        data = await response.data;
        if (response.status === 200) {
            if (getParams)
                thunkAPI.dispatch(getRentableTypes({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
const slice = createSlice({
    name: 'rentableType',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getRentableTypes.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getRentableTypes.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.rentableTypes = action.payload.data;
            state.meta = action.payload.meta;
        })
            .addCase(getRentableTypes.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getOneRentableType.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getOneRentableType.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.rentableType = action.payload;
        })
            .addCase(getOneRentableType.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(createRentableType.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(createRentableType.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(createRentableType.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(editRentableType.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(editRentableType.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(editRentableType.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(deleteRentableType.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(deleteRentableType.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(deleteRentableType.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(deleteManyRentableTypes.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(deleteManyRentableTypes.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(deleteManyRentableTypes.rejected, (state, action) => {
            state.status = 'failed';
        });
    },
});
// Reducer
export const reducer = slice.reducer;
export default slice;
